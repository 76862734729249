<template>
    <div>
        <v-card elevation="1" style="margin: 5px 10px">
            <v-row>
                <!--    卡片内容-->
                <v-btn class="topButton" depressed color="primary"
                       style="margin-right: 0px;display: inline-block" @click="historyButton()">
                    历史上报记录
                </v-btn>
            </v-row>
        </v-card>
        <v-card>
            <el-dialog
                    title="历史记录"
                    :visible.sync="historyDialogVisible"
                    width="40%">
                <v-card style="padding: 2px 4px;margin-top: 5px" v-for="(item,i) in historyIndexes" :key="i">
                    <v-container>
                        <v-row>
                            <v-col style="width: 70%">
                                <v-row style="margin: 3px 0px;">
                                    <span>等级:{{item.houseSecurityRank}}</span>
                                </v-row>
                                <v-row style="margin: 3px 0px">
                                    <span>{{item.submissionTime}}</span>
                                </v-row>
                            </v-col>
                            <v-col style="width: 20%;position: absolute;right: 0px">
                                <el-button type="primary" @click="showHistory(item.recordId)">查 看</el-button>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
                <span slot="footer" class="dialog-footer">
                  <el-button type="primary" @click="historyDialogVisible=false">关闭</el-button>
                </span>
            </el-dialog>
            <v-container fluid>
                <template>
                    <v-row v-show="record.indexes===0">
                        <v-col cols="4" v-for="i in 3">
                            <v-card>
                                <v-sheet class="pa-3">
                                    <v-skeleton-loader class="mx-auto" max-width="320" type="article, actions"
                                    ></v-skeleton-loader>
                                </v-sheet>
                            </v-card>
                        </v-col>
                    </v-row>
                    <template v-show="record.indexes.length!==0">
                        <v-row>
                            <v-col v-for="(chtemp,ci) in record.indexes"
                                   :key="ci" cols="16" sm="6" md="4" lg="4" style="margin-bottom: 10px">
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold">
                                        {{chtemp.title}}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-list dense>
                                        <v-container fluid>
                                            <v-row align="center" v-for="(ptemp,pi) in chtemp.infos">
                                                <div style="margin-left: 2px;margin-top:0;width: 12%;text-align: center">
                                                    <span class="head" style="margin-top: 0px">{{ptemp.name}}</span>
                                                </div>
                                                <v-col class="d-flex-file" cols="12" sm="5"
                                                       style="margin-left: 5px;margin-top:20px;padding: 0px">
                                                    <v-text-field v-model="ptemp.des" label="请输入" single-line
                                                                  solo></v-text-field>
                                                </v-col>
                                                <v-col class="d-flex-file" cols="12" sm="4"
                                                       style="margin-left:5px;margin-top:20px;padding: 0px">
                                                    <v-select v-model="ptemp.rank" :items="ranksGrade" label="请选择" solo
                                                              @click="rankOnClick(ptemp.rank)"></v-select>
                                                </v-col>
                                                <v-col cols="12" sm="1">
                                                    <v-file-input style="margin: 0px" prepend-icon="mdi-camera" multiple
                                                                  hide-input></v-file-input>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-list>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="4" style="margin-bottom: 10px">
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold">
                                        其他
                                    </v-card-title>
                                    <v-container>
                                        <v-textarea name="input-7-4" label="请输入描述"
                                                    v-model="record.other.des"></v-textarea>
                                        <v-file-input prepend-icon="mdi-camera" multiple hide-input></v-file-input>
                                    </v-container>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <!--                                        <v-btn color="green" dark small class="mb-2 mr-1" @click="reset(chtemp,ci)">-->
                                        <!--                                            重置-->
                                        <!--                                        </v-btn>-->
                                        <v-btn color="primary" dark small class="mb-2 mr-5" @click="submit()">
                                            保存
                                        </v-btn>
                                    </v-row>
                                </v-card>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" lg="4" style="margin-bottom: 10px">
                                <v-card>
                                    <v-card-title class="subheading font-weight-bold">
                                        房屋安全等级
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-list dense>
                                        <v-container class="px-0-radio" fluid style="margin-left: 20px">
                                            <v-radio-group v-model="record.houseRank" mandatory>
                                                <v-radio v-for="(radio,i) in houseRanks" :key="i"
                                                         :label="radio + houseRanksItems[i]"
                                                         :value="radio">
                                                </v-radio>
                                            </v-radio-group>
                                        </v-container>
                                    </v-list>
                                    <v-row>
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" dark small class="mb-2 mr-5" @click="submit()">
                                            提交
                                        </v-btn>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>
                </template>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import {
        record,
        history,
        updateRecord,
        getRanks,
    } from "@/network/Details/general_user/dailyManage.js";

    export default {
        created() {
            this.recordId = this.$route.query.recordId
            this.roomId = this.$route.query.roomId
            record({
                recordId: this.recordId,
                roomId: this.roomId
            }).then(res => {
                if (res.status === 200) {
                    this.record = res.data
                    console.log(this.record)
                }
            }).catch(err => {
                console.log(err);
            })
            history({
                roomId: this.roomId
            }).then(res => {
                if (res.status === 200) {
                    this.historyIndexes = res.data
                }
            }).catch(err => {
                console.log(err);
            })
            getRanks({}).then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    this.ranksGrade.push(res.data[i].infoRankName)
                    this.form.push({
                        des: '',
                        rank: ''
                    })
                }
                this.ranks = res.data
            }).catch(err => {
                console.log(err);
            })
        },
        name: "DailyManage",
        methods: {
            historyButton() {
                this.historyDialogVisible = true
            },
            showHistory(recordId) {
                let url = this.$router.resolve({
                    path: "/DailyHistory",
                    query: {
                        recordId: recordId
                    }
                })
                window.open(url.href, "_blank")
            },
            submit() {
                console.log(record.houseRank)
                let arr = []
                for (let i = 0; i < this.record.indexes.length; i++) {
                    for (let j = 0; j < this.record.indexes[i].infos.length; j++) {
                        let rankNum = ''
                        for (let m = 0; m < this.ranks.length; m++) {
                            if (this.ranks[m].infoRankName === this.record.indexes[i].infos[j].rank) {
                                rankNum = this.ranks[m].infoRankId
                            }
                        }
                        let item = {
                            infoId: this.record.indexes[i].infos[j].infoId,
                            recordId: this.record.recordId,
                            indexId: this.record.indexes[i].infos[j].indexId,
                            infoRank: rankNum,
                            infoDescription: this.record.indexes[i].infos[j].des,
                            infoImgUrl: '',
                        }
                        arr.push(item)
                    }
                }
                updateRecord({
                    history: {
                        houseSecurityRank: this.record.houseRank,
                        otherDescription: this.record.other.des,
                        otherImgUrl: '',
                        recordId: this.record.recordId,
                        roomId: this.roomId,
                        submissionTime: '',
                    },
                    infos: arr
                }).then(res => {
                    if (res.status === 200) {
                        this.$message({
                            type: 'success',
                            message: '提交成功!'
                        });
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                })

                //     .catch(err => {
                //     this.$message({
                //         type: 'info',
                //         message: '提交失败!'
                //     });
                // })
            },
            rankOnClick(ranks) {
                console.log(ranks)
            },
            reset(chtemp) {
                for (let k = 0; k < chtemp.infos.length; k++) {
                    this.$set(chtemp.infos[k], 'rank', '');
                    this.$set(chtemp.infos[k], 'des', '');
                }
            }
        },
        data: () => ({
            recordId: '',
            roomId: '',
            historyDialogVisible: false,
            form: [],
            dailyText: '',
            historyIndexes: '',
            record: {
                recordId: 1,
                indexes: [
                    {
                        indexId: 1,
                        title: "电器类",
                        infos: [
                            {
                                infoId: 1,
                                indexId: 5,
                                name: "插座",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 2,
                                indexId: 6,
                                name: "线路",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 3,
                                indexId: 7,
                                name: "照明",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 4,
                                indexId: 8,
                                name: "开关",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 2,
                        title: "结构类",
                        infos: [
                            {
                                infoId: 5,
                                indexId: 9,
                                name: "地面",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 6,
                                indexId: 10,
                                name: "墙面",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 7,
                                indexId: 11,
                                name: "吊顶",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 8,
                                indexId: 12,
                                name: "排水",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 3,
                        title: "大型电器类",
                        infos: [
                            {
                                infoId: 9,
                                indexId: 13,
                                name: "空调",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 10,
                                indexId: 14,
                                name: "热水器",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 11,
                                indexId: 15,
                                name: "冰箱",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 12,
                                indexId: 16,
                                name: "电视机",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    },
                    {
                        indexId: 4,
                        title: "门窗类",
                        infos: [
                            {
                                infoId: 13,
                                indexId: 17,
                                name: "入户门",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 14,
                                indexId: 18,
                                name: "卧室门",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 15,
                                indexId: 19,
                                name: "窗户",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            },
                            {
                                infoId: 16,
                                indexId: 20,
                                name: "门锁",
                                rank: "完好",
                                des: "描述信息..",
                                imgUrl: ""
                            }
                        ]
                    }
                ],
                houseRank: "A",
                other: {
                    des: "其它故障描述...",
                    imgUrl: ""
                }
            },
            ranksGrade: [],
            ranks: '',
            houseRanks: ['A', 'B', 'C', 'D'],
            houseRanksItems: [
                ':结构承载力能满足正常使用要求，未腐朽危险点，房屋结构安全。',
                '：结构承载力基本满足正常使用要求，个别结构构件处于危险状态，但不影响主体结构，基本满足正常使用要求。',
                '：部分承重结构承载力不能满足正常使用要求，局部出现险情，构成局部危房。',
                ':承重结构承载力已不能满足正常使用要求，房屋整体出现险情，构成整幢危房。'
            ]

        }),
    }
</script>

<style scoped>
</style>
