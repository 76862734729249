import {request} from "@/network/request";
export function record(data) {
    return request({
        params: data,
        method:'get',
        url:'/daily/record'
    })
}
export function history(data) {
    return request({
        params: data,
        method:'get',
        url:'/daily/history'
    })
}
export function updateRecord(data) {
    return request({
        data: data,
        method:'post',
        url:'/daily/updateRecord'
    })
}
export function getRanks(data) {
    return request({
        params: {},
        method:'get',
        url:'/daily/ranks'
    })
}
